import(/* webpackMode: "eager", webpackExports: ["GlobalProvider"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/operator/src/components/GlobalProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/operator/src/components/ui/Sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DevtoolsDialog"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/operator/src/sections/devtools/DevtoolsDialog.tsx");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/apps/proteus/operator/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/operator/src/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@14.2.4_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"nextFontInter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Maintenance"] */ "/builds/deltagreen/delta-monorepo/packages/reactLibrary/dist/index.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/packages/reactLibrary/dist/style.css");
