"use client"

import * as Sentry from "@sentry/nextjs"
import { createContext, PropsWithChildren, useContext, useEffect } from "react"

import { getServerSession } from "../server/session"

type SessionContextValue = {
  session: Awaited<ReturnType<typeof getServerSession>>
}

const SessionContext = createContext<SessionContextValue | undefined>(undefined)

export function useSession() {
  const ctx = useContext(SessionContext)
  return ctx?.session
}

export function useSafeSession() {
  const session = useSession()
  if (!session) {
    throw new Error("session is not available")
  }

  return session
}

export function useTenant() {
  const session = useSafeSession()
  const tenantId = session.user.tenantId
  return { tenantId }
}

export function SessionProvider(props: PropsWithChildren<SessionContextValue>) {
  useEffect(() => {
    if (!props.session) {
      Sentry.setUser(null)
      return
    }

    Sentry.setUser({
      id: props.session.user.id,
      email: props.session.user.email ?? undefined,
    })
  }, [props.session])

  return <SessionContext.Provider value={{ session: props.session }}>{props.children}</SessionContext.Provider>
}
